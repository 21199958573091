const Constants = {
  DISTRIBUTER: {
    distributerID: "BCIBL",
    contactEmail: "Contact@bajajcapital.com",
    loginUrlB2C: "https://dev.saving.insurance.1silverbullet.tech",
    loginUrlB2B: "https://dev.saving.insurance.1silverbullet.tech",
    distributorLogo:
      "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/image-20230223-131646.png",
    distributorLogoDark:
      "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/icons/mainlogo.png",
    contactNumber: "98111111111",
    "api_encryption": false,
    startWithDark: false,
  },
  LAZY_LOADING: {
    "inerval": "2000",
    "nooftimes": "25"
  },
  LANDING_IMGS: {
      BIKE: "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/bike.svg",
      CAR: "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Group+100+%284%29.svg",
      CARBG:"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/carbg.svg",
      BIKEBG:"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/bikebg.svg",
      BIKE_BG:"linear-gradient(180deg, hsla(133, 18%, 75%, 1) 0%, hsla(146, 20%, 79%, 1) 100%)",
      CAR_BG:"linear-gradient(180deg, hsla(30, 99%, 73%, 1) 0%, hsla(48, 99%, 65%, 1) 100%)",
      BANK:"https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/bank.svg"
  },
  IS_AUTO_COMPLETE: false,
  HAS_DARK_MODE: true,
  RM_DATA_UPLOAD_INSURER: [
    {
      name: "Select Insurer",
      value: "select",
    },
    {
      name: "ICICI Lombard",
      value: "IL",
    },
    {
      name: "Go Digit",
      value: "GODIGIT",
    },
  ],
  TEMPLATE_SETTING: {
    Email: true,
    Sms: true,
  },
  PREMIUM_BREAKUP: {
    basic_cover: ['tpPd', 'tpCngCvr', 'paCvr', 'paUnCvr', 'paPdDvr','legLibD','legLibEm'],
    Addons: ['zeroDep', 'rsa', 'engPrt', 'conExp', 'keyPrt', 'rtnlnv','ncbPrt','lossPerl','tyrePrt','fibGlass','rimCvr','wndSheld','payAsDrv'],
    Accessories:['elCvr', 'nonElCvr', 'cngCvr'],
    Discount:['volDed','antiThft','autoasso']
}


}
Object.freeze(Constants);
export function getDistributer() {
return Constants.DISTRIBUTER;
}
export function getIsAutoComplete() {
return Constants.IS_AUTO_COMPLETE;
}
export function getLandingImgs() {
  return Constants.LANDING_IMGS;
}
//Get Lazy Load constants
export function getLazyLoadingConfig() {
  return Constants.LAZY_LOADING;
}
export function getRmDataUploadInsurer() {
  return Constants.RM_DATA_UPLOAD_INSURER;
}
export function  hasDarkMode(){
  return Constants.HAS_DARK_MODE;
}

export function getTemplateSetting() {
  return Constants.TEMPLATE_SETTING;
}

export function getPremiumBreakup() {
 return Constants.PREMIUM_BREAKUP
}