<template lang="">
  <div class="loaders">
    <div>
        <p class="text-center">{{msg}}</p>
    <div class="text-center">
      <img :src="require(`@/assets/images/${imageUrl}`)" alt="" />
    </div>
    </div>
  </div>
</template>
<script>
import {getDistributer} from '@/mixins/common';
export default {
  name: "loading-page",
  props: {
    msg: String,
  },
  data() {
    return {
      imageUrl: "pageloader.gif",
    }
  },
  mounted() {
    if (getDistributer()["distributerID"]) {
      const distributorId = getDistributer()["distributerID"];
      if (distributorId === "RIBPL") {
        this.imageUrl = "wealth-right.gif";
      } else {
        this.imageUrl = `pageloader.gif`;
      }
    }
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/components/Loaders.scss";
</style>
