<template lang="">
    <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
            <innerHeaderDiy/>
            <confirmPay/>
            
        </div>
        
    </div>
</template>
<script>
import confirmPay from "../components/read-only-page/read-only-proposal-details.vue"
import innerHeaderDiy from "@/components/header/innerHeaderDiy.vue";

export default {
   components:{
    confirmPay,
    innerHeaderDiy,
   }
}
</script>
<style lang="">
    
</style>